<template>
  <teleport to="head">
    <meta name="version" :content="version" />
    <title>{{ title }}</title>
  </teleport>
  <Header v-if="$route.meta.showHeader !== undefined &&
    $route.meta.showHeader !== null &&
    $route.meta.showHeader !== false" />
  <div id="mobile-sub-header"
    v-if="$route.meta.showHeader !== false &&
    !$route.meta.hideBookTicketButton">
    <router-link class="button secondary"
      :to="{ name: 'Journey Search' }"
      tag="button">Book Tickets</router-link>
  </div>
  <main id="main">
    <Snackbar
      v-if="snackbarState"
      :message="snackbarState.message"
      :type="snackbarState.type"
      @close="closeSnackbar"
    />
    <router-view v-slot="{ Component, route }">
      <component :is="Component" :key="route.path" />
    </router-view>
  </main>
  <Footer v-if="$route.meta.showHeader !== undefined &&
    $route.meta.showHeader !== null &&
    $route.meta.showFooter !== false" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Navbars/Footer.vue'
import Snackbar from '@/components/Snackbar.vue'
import useSnackbar from '@/composables/useSnackbar'
import '@/assets/styles/colors.scss'
import '@/assets/styles/typography.scss'
import '@/assets/styles/widgets.scss'
import '@/assets/styles/inputs.scss'
import '@/assets/styles/cms_content.scss'
import { version } from '../package.json'
import useTitle from '@/composables/useTitle'
import { useHead } from '@vueuse/head'
import StopSearch from '@/components/StopSearch.vue'
import { useStops } from './composables/useStops'
export default defineComponent({
  components: { Header, Footer, Snackbar, StopSearch },
  setup () {
    const { title } = useTitle()
    // eslint-disable-next-line max-len
    const content = 'Expressway, Bus Éireann’s premium coach service, provides direct and regular services on 19 routes throughout Ireland.'
    useHead({
      meta: [
        {
          name: 'og:description',
          content: content,
          key: 'ogDescription'
        },
        {
          name: 'og:title',
          content: 'Expressway - Way Better',
          key: 'ogTitle'
        },
        {
          name: 'og:url',
          content: process.env.VUE_APP_SITE_URL,
          key: 'ogUrl'
        },
        {
          name: 'og:image',
          itemprop: 'image',
          content: `${process.env.VUE_APP_SITE_URL}images/expressway-image.jpeg`,
          key: 'ogImage'
        },
        {
          name: 'og:type',
          content: 'website',
          key: 'ogType'
        },
        {
          name: 'og:locale',
          content: 'en_IE',
          key: 'ogLocale'
        }
      ]
    })
    const { loadStops } = useStops()

    loadStops()

    return {
      ...useSnackbar(),
      version,
      title
    }
  }
})
</script>

<style lang="scss">
.grecaptcha-badge {
  visibility: hidden;
}

body.show-recaptcha .grecaptcha-badge {
  visibility: visible;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

:focus:not(:focus-visible) {
  outline: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

html,
body {
  margin: 0;
  width: 100%;
}

body {
  background-color: var(--bg-color);
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;

  main,
  #stop-search-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    position: relative;
  }

  #mobile-sub-header {
    display: none;
  }

  @media only screen and (max-width: 1199px) {
    #mobile-sub-header {
      background-color: var(--primary-color);
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: auto;
      box-sizing: border-box;

      a {
        margin: 12px 0 20px;
      }
    }
  }
}

.bg-transparent {
  background-color: transparent;
}

a[href],
input[type='submit'],
input[type='image'],
label[for],
select,
button {
  cursor: pointer;
}

@media only screen and (min-width: 789px) {
  #app main,
  #app #stop-search-container,
  #app #sub-header {
    max-width: min(100% - 30px, 960px);
  }

  .desktop-spill {
    position: relative;
    z-index: 5;

    &::before {
      background-color: inherit;
      z-index: -1;
      width: 100vw;
      left: 50%;
      top: 0;
      margin-left: -50vw;
      position: absolute;
      height: 100%;
      content: '';
    }
  }
}
</style>
