export const companyRoutes = [
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () =>
      import(/* webpackChunkName: "company" */ '@/views/ContactUs.vue'),
    meta: {
      showHeader: true,
      showFooter: true
    }
  }
]
