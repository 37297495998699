import { authenticate } from './navigationGuards'
export const userRoutes = [
  {
    path: '/my-account',
    name: 'MyAccount',
    beforeEnter: authenticate,
    component: () =>
      import(/* webpackChunkName: "myAccount" */ '../views/MyAccount.vue'),
    redirect: {
      name: 'MyAccountMenu'
    },
    children: [
      {
        path: '',
        name: 'MyAccountMenu',
        component: () =>
          import(/* webpackChunkName: "myAccount" */ '../views/MyAccountMenu.vue'),
        meta: { showBreadCrumb: false }
      },
      {
        path: '/my-account/personal-details',
        name: 'PersonalDetails',
        component: () =>
          import(/* webpackChunkName: "myAccount" */ '../views/PersonalDetails.vue'),
        meta: { showBreadCrumb: false }
      },
      {
        path: '/my-account/change-password',
        name: 'ChangePassword',
        component: () =>
          import(/* webpackChunkName: "myAccount" */ '../views/ChangePassword.vue'),
        meta: { showBreadCrumb: false }
      },
      {
        path: '/my-account/payment-details',
        name: 'PersonalPaymentDetails',
        component: () =>
          import(/* webpackChunkName: "myAccount" */'../views/PersonalPaymentDetails.vue'),
        meta: { showBreadCrumb: false }
      },
      {
        path: '/my-account/my-tickets',
        name: 'MyAccountTickets',
        component: () =>
          import(/* webpackChunkName: "myAccount" */ '../views/MyAccountTickets.vue'),
        meta: { showBreadCrumb: false }
      },
      {
        path: '/my-account/booking-info/:id',
        name: 'MyBookingInfo',
        component: () =>
          import(/* webpackChunkName: "myAccount" */ '../views/MyBookingInfo.vue'),
        meta: { showBreadCrumb: false }
      },
      {
        path: '/my-account/my-travel-passes',
        name: 'MyTravelPasses',
        component: () =>
          import(/* webpackChunkName: "myAccount" */ '../views/MyTravelPasses.vue'),
        meta: { showBreadCrumb: false }
      },
      {
        path: '/my-account/delete-account',
        name: 'DeleteAccount',
        component: () =>
          import(/* webpackChunkName: "myAccount" */ '../views/DeleteAccount.vue'),
        meta: { showBreadCrumb: false }
      }
    ],
    meta: {
      showHeader: true,
      showFooter: true
    }
  }
]
