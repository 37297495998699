export const sessionsRoutes = [
  {
    path: '/create-account',
    name: 'CreateAccount',
    component: () =>
      import(/* webpackChunkName: "sessions" */ '../views/CreateAccount.vue'),
    meta: {
      showHeader: true,
      showFooter: true
    }
  },
  {
    path: '/create-account/success',
    name: 'CreateAccountSuccess',
    props: { action: 'set' },
    component: () =>
      import(/* webpackChunkName: "sessions" */ '../views/CheckEmailCard.vue'),
    meta: {
      showHeader: true,
      showFooter: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "sessions" */ '../views/Login.vue'),
    meta: {
      showHeader: true,
      showFooter: true
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () =>
      import(/* webpackChunkName: "sessions" */ '../views/ForgotPassword.vue'),
    meta: {
      showHeader: true,
      showFooter: true
    }
  },
  {
    path: '/forgot-password/success',
    name: 'ForgotPasswordSuccess',
    props: { action: 'reset' },
    component: () =>
      import(/* webpackChunkName: "sessions" */ '../views/CheckEmailCard.vue'),
    meta: {
      showHeader: true,
      showFooter: true
    }
  },
  {
    path: '/set-password/token/:token',
    name: 'SetPassword',
    component: () =>
      import(/* webpackChunkName: "sessions" */ '../views/SetPassword.vue'),
    meta: {
      showHeader: true,
      showFooter: true
    }
  },
  {
    path: '/session-expired',
    name: 'SessionExpired',
    component: () =>
      import(/* webpackChunkName: "sessions" */ '../views/SessionExpired.vue'),
    meta: {
      showHeader: true,
      showFooter: true
    }
  }
]
