import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import GenericPage from '../views/GenericPage.vue'
import NotFound from '../views/NotFound.vue'
import { bookingsRoutes } from './bookings'
import { menuRoutes } from './menus'
import { realTimeRoutes } from './realTime'
import { sessionsRoutes } from './sessions'
import { userRoutes } from './user'
import { travelPassRoutes } from './travelPasses'
import { amendRoutes } from './amends'
import { routeRoutes } from './routes'
import { redirections } from './redirections'
import { mobileRoutes } from './mobileRoutes'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      showHeader: true,
      showFooter: true
    }
  },
  ...sessionsRoutes,
  ...userRoutes,
  ...bookingsRoutes,
  ...realTimeRoutes,
  ...menuRoutes,
  ...travelPassRoutes,
  ...amendRoutes,
  ...routeRoutes,
  ...redirections,
  {
    path: '/cookie-policy',
    name: 'Cookie Policy',
    component: () =>
      import(/* webpackChunkName: "cookies" */ '../views/CookiePolicy.vue'),
    meta: {
      showHeader: true,
      showFooter: true
    }
  },
  {
    path: '/:slug',
    name: 'Content Page',
    component: GenericPage,
    props: true,
    meta: {
      showHeader: true,
      showFooter: true
    }
  },
  ...mobileRoutes,
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: NotFound,
    meta: {
      showHeader: true,
      showFooter: true
    }
  }
]

if (process.env.NODE_ENV === 'development') {
  routes.push({
    path: '/vectors-preview',
    name: 'Vectors',
    component: () =>
      import('../views/VectorList.vue'),
    meta: {
      showHeader: true,
      showFooter: true
    }
  })
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: (to, _from, savedPosition) => {
    if (to.hash) return { el: to.hash }
    else if (savedPosition) return savedPosition
    return { left: 0, top: 0 }
  },
  routes
})

router.afterEach(() => {
  document.getElementById('main-logo')?.focus()
  return true
})

export default router
